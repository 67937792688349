
import { getLoginQrImg } from '@/api/login'
import Cookies from 'js-cookie'
import eventBus from '@/utils/event-bus'
import Captacha from './captacha'
import { getLocationForward } from '@/api/urlForward'

export default {
  name : 'Login',
  components : { Captacha },
  props : ['loginOpen', 'type'],
  data() {
    return {
      wechatGroupQrCode : '',
      icon1 : require( '@/assets/images/user.png' ),
      icon2 : require( '@/assets/images/password.png' ),
      loginForm : {
        username : '',
        password : '',
        rememberMe : false,
        uuid : '',
        captchaCode : ''
      },
      qrLogin : {
        qrUrl : '',
        scene : null
      },
      qrInterval : null,
      rules : {
        username : [
          {
            required : true,
            message : '请输入用户名或手机号码',
            trigger : 'blur'
          }
        ],
        password : [
          {
            required : true,
            message : '请输入密码',
            trigger : 'blur'
          }
        ],
        captchaCode : [
          {
            required : true,
            message : '请输入验证码',
            trigger : 'blur'
          }
        ]
      }
    }
  },

  computed : {},

  created() {

  },
  mounted() {
    const that = this
    const qrImg = localStorage.getItem( 'loginQrImg' )
    const qrScene = localStorage.getItem( 'loginQrScene' )
    if ( qrImg != undefined && qrImg != null && qrScene != undefined && qrScene != null ) {
      this.qrLogin.qrUrl = qrImg
      this.qrLogin.scene = qrScene
    } else {
      this.queryScanImg()
    }
    this.qrInterval = setInterval( function() {
      that.$store
        .dispatch( 'CheckSceneId', that.qrLogin.scene )
        .then( ( res ) => {
          if ( res.flag == 1 ) {
            that.$store.dispatch( 'GetInfo' )
            setTimeout( () => {
              that.handleClose()
              that.loginOver()
            }, 500 )
            setTimeout( () => {
              if ( that.type && that.type == 1 ) {
                window.open( `https://${window.location.hostname}/console/dashboard`, '_self' )
              }
            }, 500 )
          }
        } )
    }, 1500 )
    this.getCookie()

    const search = window.location.search
    let url = window.location.protocol + '//' + window.location.host
    if ( search ) {
      url += search
    }
    getLocationForward( url ).then( res => {
      let data
      if ( !res.data ) {
        data = JSON.parse( res.defaultUrl.configParams )
      } else {
        data = JSON.parse( res.data.configParams )
      }
      data.forEach( i => {
        if ( i.keyName === 'wechatGroupQrCode' ) {
          this.wechatGroupQrCode = i.value
        }
      } )
    } )
  },
  methods : {
    queryScanImg() {
      const r = getLoginQrImg( process.env.MINI_PG_VERSION )
      r.then( ( res ) => {
        if ( res.code == 200 ) {
          this.qrLogin.qrUrl = res.data
          this.qrLogin.scene = res.scene

          localStorage.setItem( 'loginQrImg', res.data )
          localStorage.setItem( 'loginQrScene', res.scene )
        }
      } )
    },
    imgError( e ) {
      this.queryScanImg()
      e.srcElement.onerror = null
    },
    getCookie() {
      const username = Cookies.get( 'username' )
      const password = Cookies.get( 'password' )
      const rememberMe = Cookies.get( 'rememberMe' )
      this.loginForm = {
        username : username === undefined ? this.loginForm.username : username,
        password : password === undefined ? this.loginForm.password : password,
        rememberMe : rememberMe === undefined ? false : Boolean( rememberMe )
      }
    },
    handleClose() {
      this.$refs.form.clearValidate()
      clearInterval( this.qrInterval )
      eventBus.$emit( 'loginOrRegister', 'closeRegister' )
      eventBus.$emit( 'loginOrRegister', 'closeLogin' )
    },
    loginOver() {
      this.$refs.form.clearValidate()
      clearInterval( this.qrInterval )
      eventBus.$emit( 'loginOver', true )
      eventBus.$emit( 'login' )
    },
    handleCaptcha() {
      if ( !this.loginForm.username || !this.loginForm.password ) {
        this.$message.error( '请输入用户名或密码' )
        return
      }
      this.$refs.captacha.openCaptcha()
    },
    handleCaptchaSubmit( param ) {
      this.loginForm.captchaCode = param.captchaVal
      this.loginForm.uuid = param.uuid
      this.handleLogin( 'form' )
    },
    // 密码登录
    handleLogin( formName ) {
      const _this = this
      this.$refs[formName].validate( ( valid ) => {
        if ( valid ) {
          Cookies.remove( 'username' )
          Cookies.remove( 'password' )
          Cookies.remove( 'rememberMe' )
          this.$store
            .dispatch( 'LoginWeb', this.loginForm )
            .then( () => {
              this.$store.dispatch( 'GetInfo' )
              setTimeout( () => {
                this.handleClose()
                this.loginOver()
                eventBus.$emit( 'login' )
                if ( _this.type ) {
                  window.open( `/console/dashboard`, '_self' )
                }
              }, 500 )
            } )
            .catch( () => {
            } )
        }
      } )
    },
    toReg() {
      clearInterval( this.qrInterval )
      eventBus.$emit( 'loginOrRegister', 'closeLogin' )
      eventBus.$emit( 'loginOrRegister', 'openRegister' )
    },
    toForget() {
      clearInterval( this.qrInterval )
      eventBus.$emit( 'loginOrRegister', 'closeLogin' )
      eventBus.$emit( 'loginOrRegister', 'openForget' )
    }
  }
}

