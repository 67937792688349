
import { captchaImage } from '@/api/login'
export default {
  name : 'Captcha',
  components : {},
  data() {
    return {
      captchaOpen : false,
      captchaSrc : '',
      captchaVal : '',
      uuid : ''
    }
  },
  computed : {},
  created() {

  },
  mounted() {

  },
  methods : {
    refreshCaptcha() {
      captchaImage().then( ( res ) => {
        this.captchaSrc = 'data:image/png;base64,' + res.img
        this.uuid = res.uuid
      } )
    },
    openCaptcha() {
      this.captchaOpen = true
      captchaImage().then( ( res ) => {
        this.captchaSrc = 'data:image/png;base64,' + res.img
        this.uuid = res.uuid
      } )
    },
    submitCaptcha() {
      if ( this.captchaVal ) {
        const param = {
          captchaVal : this.captchaVal,
          uuid : this.uuid
        }
        this.captchaVal = ''
        this.uuid = ''
        this.captchaOpen = false
        this.$emit( 'submitCa', param )
      } else {
        this.$message.error( '请输入验证码' )
      }
    }
  }
}

