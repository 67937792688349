
import { getLoginQrImg, sendSms, resetPwdByMobile } from '@/api/login'
import eventBus from '@/utils/event-bus'
import Captacha from '@/components/LoAndRe/captacha'

export default {
  name : 'Register',
  components : { Captacha },
  props : ['registerOpen'],
  data() {
    const pwdReg = ( rule, value, callback ) => {
      const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()]{8,32}$/g
      if ( reg.test( value ) ) {
        callback()
      } else {
        callback( new Error( '密码格式错误！请确保密码长度为8到32位，且由大小字母，数字，特殊符号组成！' ) )
      }
    }
    return {
      qrLogin : {
        qrUrl : '',
        scene : null
      },
      time : 60,
      timer : null,
      isClick : false,
      checked : false,
      qrInterval : null,
      form : {
        username : '',
        code : '',
        password : ''
      },
      rules : {
        username : [
          {
            required : true,
            pattern : /^1[2|3|4|5|6|7|8|9][0-9]\d{8}$/,
            message : '请输入正确的手机号码',
            trigger : 'blur'
          }
        ],
        code : [
          { required : true, message : '请输入验证码', trigger : 'blur' }
        ],
        password : [
          {
            required : true,
            message : '密码不能为空',
            trigger : 'blur'
          },
          {
            validator : pwdReg,
            trigger : 'blur'
          }
        ]
      }
    }
  },

  computed : {},

  created() {

  },
  mounted() {
    const that = this
    const qrImg = localStorage.getItem( 'loginQrImg' )
    const qrScene = localStorage.getItem( 'loginQrScene' )
    if ( qrImg != undefined && qrImg != null && qrScene != undefined && qrScene != null ) {
      this.qrLogin.qrUrl = qrImg
      this.qrLogin.scene = qrScene
    } else {
      const r = getLoginQrImg( process.env.MINI_PG_VERSION )
      r.then( ( res ) => {
        if ( res.code == 200 ) {
          this.qrLogin.qrUrl = res.data
          this.qrLogin.scene = res.scene

          localStorage.setItem( 'loginQrImg', res.data )
          localStorage.setItem( 'loginQrScene', res.scene )
        }
      } )
    }
    this.qrInterval = setInterval( function() {
      that.$store
        .dispatch( 'CheckSceneId', that.qrLogin.scene )
        .then( ( res ) => {
          if ( res.flag == 1 ) {
            that.closed()
            eventBus.$emit( 'loginOver', true )
            that.$store.dispatch( 'GetInfo' )
            clearInterval( this.qrInterval )

            sessionStorage.setItem( 'reload', 2 )

            setTimeout( () => {
              if ( that._props.type && that._props.type == 1 ) {
                window.open( `https://${window.location.hostname}/console/dashboard`, '_blank' )
              }
            }, 100 )
          }
        } )
    }, 1500 )
  },
  updated() {
  },
  methods : {
    toLogin() {
      clearInterval( this.qrInterval )
      eventBus.$emit( 'loginOrRegister', 'closeForget' )
      eventBus.$emit( 'loginOrRegister', 'openLogin' )
    },
    closed() {
      this.form = {
        username : '',
        password : '',
        code : ''
      }
      clearInterval( this.qrInterval )
      this.$refs.form.clearValidate()
      eventBus.$emit( 'loginOrRegister', 'closeRegister' )
      eventBus.$emit( 'loginOrRegister', 'closeForget' )
      eventBus.$emit( 'loginOrRegister', 'closeLogin' )
    },
    getCaptcha() {
      this.isClick = true
      const _this = this
      this.timer = setInterval( function() {
        _this.time = _this.time - 1
        if ( _this.time == 0 ) {
          clearInterval( _this.timer )
          _this.time = 60
          _this.isClick = false
        }
      }, 1000 )
    },
    sendSms( param ) {
      this.form.captchaCode = param.captchaVal
      this.form.uuid = param.uuid
      sendSms( this.form ).then( ( res ) => {
        this.$message.success( '发送成功，请注意查看手机' )
        // 开始倒计时
        this.getCaptcha()
      } )
    },
    openCaptcha() {
      if ( this.form.username == null || this.form.username == '' ) {
        this.$message( {
          type : 'warning',
          message : '手机号不能为空'
        } )
      } else {
        const reg = /^1[3456789]\d{9}$/
        if ( !reg.test( this.form.username ) ) {
          // this.$modal.msgError("");
          this.$message( {
            type : 'warning',
            message : '手机号格式错误'
          } )
        } else {
          this.$refs.captacha.openCaptcha()
        }
      }
    },
    submitForm( formName ) {
      // if (!this.checked) {
      //   this.$message({
      //     type: 'warning',
      //     message: '请勾选用户协议'
      //   })
      //   return
      // }

      this.$refs[formName].validate( ( valid ) => {
        if ( valid ) {
          resetPwdByMobile( this.form ).then( res => {
            if ( res.code == 200 ) {
              this.$message( {
                type : 'success',
                message : '操作成功'
              } )
              this.toLogin()
            }
          } ).catch( ( error ) => {
            console.log( error )
          } )
        } else {
          console.log( 'error submit!!' )
          return false
        }
      } )
    }
  }
}

