
import { getLabelPrintTypes, getPublicLabelList, getPrintMethod, getPrintSize } from '@/api/login.js'
export default {
  name : 'PrintDialog',
  components : {},
  data() {
    return {
      dialogVisible : false,
      name : '',
      activeMenu : null,
      img1 : require( '../../assets/images/print/1.png' ),
      img2 : require( '../../assets/images/print/2.png' ),
      typeMenu : [
        {
          icon : require( '../../assets/images/print/3.png' ),
          name : '物品标签'
        },
        {
          icon : require( '../../assets/images/print/4.png' ),
          name : '人员工牌'
        },
        {
          icon : require( '../../assets/images/print/5.png' ),
          name : '设备标牌'
        },
        {
          icon : require( '../../assets/images/print/6.png' ),
          name : '商务活动'
        },
        {
          icon : require( '../../assets/images/print/7.png' ),
          name : '在线分享'
        },
        {
          icon : require( '../../assets/images/print/8.png' ),
          name : '封口贴'
        },
        {
          icon : require( '../../assets/images/print/9.png' ),
          name : '其他'
        }
      ],
      printSize : '',
      printSizes : [],
      printMethod : '',
      printMethods : [],
      printTempList : [],
      num : 1
    }
  },

  computed : {},

  created() { },
  mounted() { },

  methods : {
    openDialog() {
      this.dialogVisible = true
      this.getLabelPrintTypes()
      this.getPrintMethod()
      this.getPrintSize()
    },
    handleClose() {
      this.dialogVisible = false
    },
    selectMenuType( item ) {
      this.activeMenu = item.dictValue
      this.getPublicLabelList()
      this.$forceUpdate()
    },
    getPrintMethod() {
      getPrintMethod().then( res => {
        this.printMethods = res.rows
      } )
    },
    getPrintSize() {
      getPrintSize().then( res => {
        this.printSizes = res.rows
      } )
    },
    getLabelPrintTypes() {
      getLabelPrintTypes().then( res => {
        this.activeMenu = res.rows[0].dictValue
        this.getPublicLabelList()
        res.rows.map( i => {
          this.typeMenu.map( k => {
            if ( k.name == i.dictLabel ) {
              k.dictValue = i.dictValue
            }
          } )
        } )
      } )
    },
    getPublicLabelList() {
      const p = {
        dictValue : this.activeMenu,
        printMethod : this.printMethod,
        printSize : this.printSize,
        pageNum : this.num
      }
      getPublicLabelList( p ).then( res => {
        this.printTempList = res.rows
      } )
    },
    load() {
      this.num += 1
      this.getPublicLabelList()
    },
    jumpEdit( v ) {
      window.open( `/label-design/#/?type=${v}` )
    },
    jumpPrintSystem( v ) {
      window.open( `/label-design/#/?type=1&pid=${v}` )
    }
  }
}

